import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilService } from 'src/app/service/util.service';
import { API } from 'src/environments/environment';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-relatorios-gerenciais',
  templateUrl: './relatorios-gerenciais.component.html',
  styleUrls: ['./relatorios-gerenciais.component.scss']
})
export class RelatoriosGerenciaisComponent implements OnInit {

public planosContratados :number ;
public testamentosComSinistroAberto :number ;
public advogadosContratantes :number ;
public novosAdvogadosCadastrados :number ;
public testamentosEmPreenchimento :number ;
public testamentosAguardandoPagamento :number ;
public testamentosAguardandoAssinatura:number ;
public testamentosConcluidos :number ;
public testamentosCancelados :number ;
public isSuperAdmin:boolean =false;
@Input() periodoDeFiltroDashboard: string='';
@Input() periodoAteFiltroDashboard: string='';
@Input() buscarDados: any;

constructor(public util: UtilService, private ajax: AjaxService,private auth: AuthService) {}

async ngOnInit() {
  await this.buscarDadosDashbord();
  let usuarioLogado = this.auth.getUser().user;
  this.isSuperAdmin = usuarioLogado.isSuperAdmin;
}

async buscarDadosDashbord(){

let dados={
  De:this.periodoDeFiltroDashboard,
  Ate:this.periodoAteFiltroDashboard
}

    const url = `${API}TestamentoDigital/TestamentoEmpresaDashboard`;
      await this.ajax.postAsync(url,dados,response => {
            this.planosContratados = response.planosContratados ;
            this.testamentosComSinistroAberto =response.testamentosComSinistroAberto ;
            this.advogadosContratantes= response.advogadosContratantes ;
            this.novosAdvogadosCadastrados= response.novosAdvogadosCadastrados ;
            this.testamentosEmPreenchimento =response.testamentosEmPreenchimento ;
            this.testamentosAguardandoPagamento =response.testamentosAguardandoPagamento ;
            this.testamentosAguardandoAssinatura = response.testamentosAguardandoAssinatura ;
            this.testamentosConcluidos =  response.testamentosConcluidos ;
            this.testamentosCancelados=response.testamentosCancelados ;
    });
  }
}
