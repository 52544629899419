import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponseNet } from 'src/app/model/ResponseNet.model';
import { jwtDecode,  ResponseUser, User } from 'src/app/model/User.model';
import { AjaxService } from 'src/app/service/ajax.service';
import { AuthService } from 'src/app/service/auth.service';
import { UtilService } from 'src/app/service/util.service';
import { API, maskCPF } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email: string;
  senha: string;
  recuperarCpf:string;
  jwt_decode: jwtDecode;
  user : ResponseUser = new ResponseUser();
  public exibirRecuperacaoDeSenha:boolean = false;
  public maskCPF = maskCPF;
  public chaveAcesso: string;
  public chaveAcessoExecutor: string;
  public idTipoEntregaChave:any[];
  public beneficiariosSinistroAberto:any[];
  public idTestamentoEmpresaLogin:string;
  public idTestamentoEmpresaAcessoClienteLogin:string;
  public codigoAtivacaoLogin:string;
  constructor(
    private router: Router,
    private ajax: AjaxService,
    public util: UtilService,
    private auth: AuthService,
    private route: ActivatedRoute
  ) {
    debugger;
    this.user.user = new User();
    this.user.token = "";
    this.idTipoEntregaChave=[];

      this.route.queryParams.subscribe(params => {
      this.idTestamentoEmpresaLogin = params['IdTestamentoEmpresa'] ??params['IDTESTAMENTOEMPRESA'] ;
      this.idTestamentoEmpresaAcessoClienteLogin = params['IdTestamentoEmpresaAcessoCliente'] ?? params["IDTESTAMENTOEMPRESAACESSOCLIENTE"];
      this.codigoAtivacaoLogin = params['codigoAtivacao'] ?? params["CODIGOATIVACAO"];
    });

   }

  ngOnInit() {
    debugger;
    this.email = '';
    this.senha = '';
    this.chaveAcessoExecutor = "";
    this.chaveAcesso = "";

    if(this.idTestamentoEmpresaAcessoClienteLogin && this.idTestamentoEmpresaLogin && this.codigoAtivacaoLogin){
      this.loginAcessoDireto();
    }
  }

  async selecionarMotivo(e: any) {
    let id =  e.target.value;
    var checked = e.target.checked;

    if(checked){
      this.idTipoEntregaChave.push(id);
    }
    else{
      var index = this.idTipoEntregaChave.indexOf(id);
      this.idTipoEntregaChave.splice(index, 1);
    }
  }

  async comunicarSinistro():Promise<void>{
    if(this.chaveAcessoExecutor != null  && this.chaveAcesso != null && this.idTipoEntregaChave.length){

      var url = `${API}TestamentoDigital/ComunicarSinistro`;
      var dados = {
        ChaveExecutor:this.chaveAcessoExecutor,
        Chave:this.chaveAcesso,
        IdTipoEntregaChave : this.idTipoEntregaChave
      };

      await this.ajax.postAsync(url,dados,response =>{
        if(response.erro)
          this.util.toasterShowError("Atenção", response.mensagem) ;
          else
          {
           this.beneficiariosSinistroAberto = response.beneficiarios;
          }
      });

    }else{
      this.util.toasterShowError("Atenção", "Preencha Todos os Dados");
    }
  }

  esconderModalSinistroComunicado(){
    this.beneficiariosSinistroAberto = null;
  }

  validar(): boolean{

    if (this.email == "" ) {
      this.util.toasterShowError("Atenção", "Preencha o Cpf");
      return false;
    }

    if (this.senha == "") {
      this.util.toasterShowError("Atenção", "Preencha o campo Senha");
      return false;
    }

    return true;
  }

  mostrarRecuperacaoDeSenha(){
    this.recuperarCpf='';
    this.exibirRecuperacaoDeSenha=true;
  }

  mostrarTelaDeLogin(){
    this.recuperarCpf='';
    this.exibirRecuperacaoDeSenha=false;
  }

async enviarEmailRecuperacaoDeSenha(){

  if (this.recuperarCpf == "" ) {
    this.util.toasterShowError("Atenção", "Preencha o Cpf");
    return false;
  }

  var url = `${API}auth/User/${this.recuperarCpf}/GerarLinkRedefinicaoPorEmail`;
  await this.ajax.postAsync(url, null,response =>{
    this.util.toasterShowSuccess("Sucesso", "E-mail de recuperação de senha enviado para o E-mail cadastrado!");
  });
}

async loginAcessoDireto(){

    var url = `${API}Auth/User/LoginAcessoDireto?idTestamentoEmpresaAcessoCliente=${this.idTestamentoEmpresaAcessoClienteLogin}&idTestamentoEmpresa=${this.idTestamentoEmpresaLogin}&codigoAtivacao=${this.codigoAtivacaoLogin}`;
    await this.ajax.getAsync(url,response =>{
      this.jwt_decode = this.util.getDecodedAccessToken(response.token);
      this.user.user.nome = response.user.nome;
      this.user.user.idCliente = response.user.idCliente;
      this.user.user.id = response.user.id;
      this.user.user.idImutavel = response.user.idImutavel;
      this.user.user.email = response.user.email;
      this.user.user.telefone = response.user.telefone;
      this.user.user.dataNascimento = response.user.dataNascimento;
      this.user.user.cpf = response.user.cpf;
      this.user.user.isAdvogado = response.user.isAdvogado;
      this.user.user.isSuperAdmin = response.user.isSuperAdmin;
      this.user.user.isClienteAcessoTestadorDireto = response.user.isClienteAcessoTestadorDireto;
      this.user.user.isAdministradorEmpresa = response.user.isAdministradorEmpresa;
      this.user.user.idUsuarioEmpresa = response.user.idUsuarioEmpresa;
      this.user.user.idUsuarioAdministradorEmpresa = response.user.idUsuarioAdministradorEmpresa;
      this.user.user.isTestador = response.user.isTestador;
      this.user.user.isTestadorClienteAdvogado = response.user.isTestadorClienteAdvogado;
      this.user.token = response.token;
      this.user.originalUser = this.user.user;
      this.auth.setUser(this.user);
      this.util.toasterShowSuccess("", "Bem-vindo(a) " + this.user.user.nome.toUpperCase());
      let urlRed = API + "TestamentoDigital/Testamento";

      if( this.user.user.isAdministradorEmpresa || this.user.user.isAdvogado)
      {
        this.router.navigate(['/gerenciar-testamento']);
      }
      else{
      this.ajax.get(urlRed).
      subscribe(response => {
        if(response?.testamentos?.length > 0)
          this.router.navigate(['/gerenciar-testamento']);
        else
          this.router.navigate(['/home']);

      }, error => {
        this.router.navigate(['/home']);
      });
    }

    });

}

  async login(){
    if(this.validar()){

      var url = API + 'Auth/User?login='+this.email+'&senha='+this.senha;
      await this.ajax.getAsync(url,response =>{
        this.jwt_decode = this.util.getDecodedAccessToken(response.token);
        this.user.user.nome = response.user.nome;
        this.user.user.idCliente = response.user.idCliente;
        this.user.user.id = response.user.id;
        this.user.user.idImutavel = response.user.idImutavel;
        this.user.user.email = response.user.email;
        this.user.user.telefone = response.user.telefone;
        this.user.user.dataNascimento = response.user.dataNascimento;
        this.user.user.cpf = response.user.cpf;
        this.user.user.isAdvogado = response.user.isAdvogado;
        this.user.user.isSuperAdmin = response.user.isSuperAdmin;
        this.user.user.isClienteAcessoTestadorDireto = response.user.isClienteAcessoTestadorDireto;
        this.user.user.isAdministradorEmpresa = response.user.isAdministradorEmpresa;
        this.user.user.idUsuarioEmpresa = response.user.idUsuarioEmpresa;
        this.user.user.idUsuarioAdministradorEmpresa = response.user.idUsuarioAdministradorEmpresa;
        this.user.user.isTestador = response.user.isTestador;
        this.user.user.isTestadorClienteAdvogado = response.user.isTestadorClienteAdvogado;
        this.user.token = response.token;
        this.user.originalUser = this.user.user;
        this.auth.setUser(this.user);
        this.util.toasterShowSuccess("", "Bem-vindo(a) " + this.user.user.nome.toUpperCase());
        let urlRed = API + "TestamentoDigital/Testamento";

        if( this.user.user.isAdministradorEmpresa || this.user.user.isAdvogado)
        {
          this.router.navigate(['/gerenciar-testamento']);
        }
        else{
        this.ajax.get(urlRed).
        subscribe(response => {
          if(response?.testamentos?.length > 0)
            this.router.navigate(['/gerenciar-testamento']);
          else
            this.router.navigate(['/home']);

        }, error => {
          this.router.navigate(['/home']);
        });
      }

      });
    }
  }
}
