
<div class="row my-5">
    <div *ngIf="isSuperAdmin" class="col-3 grafRedondo corVerde">
        <i class="fas fa-caret-up"></i>
        <h1 class="corAzul">{{planosContratados}}</h1>
        <p class="textGraf">PLANOS CONTRATADOS</p>
        <i class="fas fa-caret-down disabled"></i>
    </div>
    <div class="col-3 grafRedondo corPrincipal">
        <i class="fas fa-caret-up disabled"></i>
        <h1 class="corAzul">{{testamentosComSinistroAberto}}</h1>
        <p class="textGraf">SINISTROS ABERTOS</p>
        <i class="fas fa-caret-down"></i>
    </div>
    <div class="col-3 grafRedondo corAzul">
        <i class="fas fa-caret-up corVerde"></i>
        <h1 class="corAzul">{{advogadosContratantes}}</h1>
        <p class="textGraf">ADVOGADOS / ESCRITÓRIOS CONTRATANTES</p>
        <i class="fas fa-caret-down disabled"></i>
    </div>
    <div class="col-3 grafRedondo corVerde">
        <i class="fas fa-caret-up"></i>
        <h1 class="corAzul">{{novosAdvogadosCadastrados}}</h1>
        <p class="textGraf">NOVOS ADVOGADOS CADASTRADOS</p>
        <i class="fas fa-caret-down disabled"></i>
    </div>
</div>

<div class="rowGrafInferior row my-5 d-flex justify-content-center align-items-center">
    <div class="col-3 grafInferior">
        <div class="d-flex flex-direction-column">
            <i class="fas fa-caret-up corVerde"></i>
            <i class="fas fa-caret-up corVerde disabled"></i>
        </div>
        <div>
            <h1 class="corAzul">{{testamentosEmPreenchimento}}</h1>
            <p class="textGraf">testamentos em preenchimento</p>
        </div>
    </div>
    <div class="col-3 grafInferior">
        <div class="d-flex flex-direction-column">
            <i class="fas fa-caret-down corPrincipal disabled"></i>
            <i class="fas fa-caret-down corPrincipal"></i>
        </div>
        <div>
            <h1 class="corAzul">{{testamentosAguardandoAssinatura}}</h1>
            <p class="textGraf">aguardando assinatura</p>
        </div>
    </div>
    <div class="col-3 grafInferior">
        <div class="d-flex flex-direction-column">
            <i class="fas fa-caret-up corVerde"></i>
            <i class="fas fa-caret-up corVerde disabled"></i>
        </div>
        <div>
            <h1 class="corAzul">{{testamentosConcluidos}}</h1>
            <p class="textGraf">testamentos concluídos</p>
        </div>
    </div>
    <div class="col-3 grafInferior">
        <div class="d-flex flex-direction-column">
            <i class="fas fa-caret-down corPrincipal disabled"></i>
            <i class="fas fa-caret-down corPrincipal"></i>
        </div>
        <div>
            <h1 class="corAzul">{{testamentosCancelados}}</h1>
            <p class="textGraf">testamentos cancelados</p>
        </div>
    </div>
</div>
