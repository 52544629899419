// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import createNumberMask from "text-mask-addons/dist/createNumberMask";


export const environment = {
  production: false
};

export const SecretToken = 'ab1b46a7-2f9a-416b-b0c1-ec67ad19cf96';
export const REDIRECT_SITE = "https://meutestamentodigital.com/";
//export const API = "https://api.meutestamento.lumago.com.br/api/";
//export const API = "https://localhost:5001/api/";
//export const API = "https://localhost:44330/api/";

//PRODUÇÃO
export const API = "https://meutestamentodigitalapi.azurewebsites.net/api/";

export const MOBILE_RES_VALUE = 991;

export const TIPOS = { EntradaDeDados: "39943721-e576-491a-80ab-59bce4e3c435" };

export const TIPO_ARQUIVO = {
  Testamento: "eff963a1-cbc8-4e70-8a75-248a0a221dc0",
  Documento: "eff963a1-cbc8-4e70-8a75-248a0a221dc1",
  Foto: "eff963a1-cbc8-4e70-8a75-248a0a221dc2",
  Video: "eff963a1-cbc8-4e70-8a75-248a0a221dc3",
  Audio: "eff963a1-cbc8-4e70-8a75-248a0a221dc4",
  Outro: "eff963a1-cbc8-4e70-8a75-248a0a221dc5",
  Meeting:"eff963a1-cbc8-4e70-8a75-248a0a221dc6"
}

export const TIPO_STATUS_TESTAMENTO = {
   'Agardando Assinatura Testemunhas' :"bff963a1-cbc8-4e70-8a75-248a0a221dc4"
  ,'Agardando Pagamento' :"bff963a1-cbc8-4e70-8a75-248a0a221dc2"
  ,Cancelado :"bff963a1-cbc8-4e70-8a75-248a0a221dc1"
  ,Concluido :"bff963a1-cbc8-4e70-8a75-248a0a221dc3"
  ,'Em Andamento' :"bff963a1-cbc8-4e70-8a75-248a0a221dc0"
  ,'Sinistro Aberto' :"bff963a1-cbc8-4e70-8a75-248a0a221dc5"
}

export const TIPO_USUARIO_EMPRESA = {
  Admin :"ecf963a1-cbc8-4e70-8a75-248a0a221dc1"
 ,Advogado :"ecf963a1-cbc8-4e70-8a75-248a0a221dc2"
}

 export const GetTiposArquivo = ()=>{
    let array = [];
    for(var p in TIPO_ARQUIVO){
      array.push({value:TIPO_ARQUIVO[p],name:p});
    }

    return array;
  }

  export const GetTiposUsuarioEmpresa = ()=>{
    let array = [];
    for(var p in TIPO_USUARIO_EMPRESA){
      array.push({value:TIPO_USUARIO_EMPRESA[p],name:p});
    }

    return array;
  }

  export const GetStatusTestamento =()=>{
    let array = [];
    for(var p in TIPO_STATUS_TESTAMENTO){
      array.push({value:TIPO_STATUS_TESTAMENTO[p],name:p});
    }
    return array;
  }

export const maskTelefoneCelular = ['(', /\d/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
export const maskTeleFixo = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
export const maskCPF = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
export const maskData = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
export const maskCEP = [/\d/, /\d/, ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/ ];
export const maskMes = [/\d/, /\d/];
export const maskAno = [/\d/, /\d/];
export const maskCVV = [/\d/, /\d/, /\d/];
export const maskNumeroCartao = [/\d/, /\d/,/\d/, /\d/, ' ',/\d/, /\d/,/\d/, /\d/, ' ',/\d/, /\d/,/\d/, /\d/, ' ',/\d/, /\d/,/\d/, /\d/];
export const maskOnlyNumbers = [/\d/, /\d/];


export const numberMaskInt = createNumberMask({
  prefix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  decimalSymbol: ',',
  allowDecimal: false,
  decimalLimit: 0,
  requireDecimal: false,
  allowNegative: false,
  allowLeadingZeroes: false,
  //integerLimit: 8
})
export const numberMaskReal = createNumberMask({
  prefix: 'R$ ',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  decimalSymbol: ',',
  allowDecimal: true,
  decimalLimit: 2,
  requireDecimal: false,
  allowNegative: false,
  allowLeadingZeroes: false,
  //integerLimit: 8
})

export const TIPO_TESTAMENTO = { comDisposicaoBens: "aff963a1-cbc8-4e70-8a75-248a0a221dc1", semDisposicaoBens: "aff963a1-cbc8-4e70-8a75-248a0a221dc2" }

export const MENSAGEM = {
  irEmDisporBens: "Antes de prosseguir é necessário ir na aba 'Testamento' e responder se irá dispor de bens<br>Obrigado!",
  construcaoExecutoresAceitarTermo:"Seu Testamento Digital foi Criptografado. A Chave de Acesso será enviada por e-mail após a assinatura do Termo de Compromisso por parte do(s) Executor(es).",
  construcaoTestemunhasAssinarem:"Seu Testamento Digital foi Criptografado. A Chave de Acesso será enviada por e-mail após a assinatura do Testamento por parte das Testemunhas e do Termo de Compromisso por parte do(s) Executor(es)."
};




/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
